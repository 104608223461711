const defaultOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
}

export default (value, customOptions = {}) => {
  if (!value) {
    return value
  }

  const date = new Date(value)
  return date.toLocaleDateString('nl-NL', {
    ...defaultOptions,
    ...customOptions,
    timeZone: 'Europe/Amsterdam',
  })
}
